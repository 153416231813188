import React, { Component } from "react"
import styled from "styled-components"

class BoxTwo extends Component {
  render() {
    return (
      <MainBox>
        <Box>
          <BoxHeader>Sell to the finish line</BoxHeader>
          <BoxContent>
            Focus on what matters — winning over your customers and close more.
            Take control of your business and remove friction from your sales
            processes. Access, analyze and collaborate on relevant opportunities
            and tasks on the go.
          </BoxContent>
        </Box>
      </MainBox>
    )
  }
}
export default BoxTwo

const MainBox = styled.div`
  background: #2a1e5c;

  display: grid;
  justify-content: center;
  align-content: center;
  /* @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  } */
`
const Box = styled.div`
  text-align: center;
  height: 50vh;
  width: 60vw;
  display: grid;
  justify-content: center;
  align-content: center;
  @media screen and (max-width: 600px) {
    height: auto;
    width: auto;
    padding: 20px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: auto;
    width: auto;
    padding: 20px;
  }
`
const BoxHeader = styled.h2`
  font-family: "Roboto";
  font-weight: 500;
  font-size: 2.75em;
  text-align: center;
  color: #ffffff;
`
const BoxContent = styled.p`
  font-family: "Roboto";
  font-weight: 100;
  font-size: 1.125em;
  text-align: center;
  color: #eafaff;
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`
