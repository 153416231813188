import React, { Component } from "react"

import STAFFINGTHREEFIRST from "../../../images/staffing/one.jpeg"
import STAFFINGTHREESECOND from "../../../images/staffing/two.jpeg"
import STAFFINGTHREETHIRD from "../../../images/staffing/three.jpeg"

import STAFFINGTHREEFOURTH from "../../../images/staffing/four.jpeg"
// import STAFFINGTHREEFIFTH from "../../../images/staffing/"
import CIRCLETICK from "../../../images/greencircletick.png"

import styled from "styled-components"
class BoxThree extends Component {
  render() {
    return (
      <MainBox>
        {/* Out Of The Box */}
        <Box>
          <BoxRightMobile>
            <ImageBox>
              <img
                src={STAFFINGTHREEFIRST}
                alt="one"
                width="100%"
                height="100%"
                // style="vertical-align:middle"
              />
            </ImageBox>
          </BoxRightMobile>
          <BoxLeft>
            <ContentWrapper>
              <BoxLeftMainHeader>Bring on More Requirements</BoxLeftMainHeader>
              <BoxLeftContent1> </BoxLeftContent1>

              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Korero’s solution allows you to create as many
                requirements in an opportunity and attach multiple candidates
                for each position.
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Leverage Search based on multi parameters to place the
                candidate – “do it right the first time”.
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                {" "}
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Capture feedback and share with your colleagues, maintain
                candidate history and prep each candidate to score better in
                their process.
              </BoxLeftContent>
            </ContentWrapper>
          </BoxLeft>
          <BoxRight>
            <ImageBox>
              <img
                src={STAFFINGTHREEFIRST}
                alt="one"
                width="100%"
                height="100%"
                // style="vertical-align:middle"
              />
            </ImageBox>
          </BoxRight>
        </Box>
        <Spacer />
        <Box>
          <BoxRightMobile>
            <ImageBox>
              <img src={STAFFINGTHREESECOND} alt="secondimage" width="100%" />
            </ImageBox>
          </BoxRightMobile>
          <BoxRight>
            <ImageBox>
              {" "}
              <img src={STAFFINGTHREESECOND} alt="secondimage" width="100%" />
            </ImageBox>
          </BoxRight>

          <BoxLeft>
            <ContentWrapper>
              <BoxLeftMainHeader>
                Automate, reduce turn-around time
              </BoxLeftMainHeader>
              <BoxLeftContent1> </BoxLeftContent1>

              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Rules simplified will help an enterprise turn around
                tasks faster, automate routine tasks to improve candidate and
                customer engagement.
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Empower your team by removing mundane tasks so that they
                focus on building deeper relationship with customer and
                candidate
                <br />
              </BoxLeftContent>
              {/* <BoxLeftContent>
                {" "}
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Focus more on the opportunity and closure, leave the
                administrative tasks to Korero.
              </BoxLeftContent> */}
            </ContentWrapper>
          </BoxLeft>
        </Box>
        <Spacer />

        <Box>
          <BoxRightMobile>
            <ImageBox>
              <img src={STAFFINGTHREETHIRD} alt="thirdimage" width="100%" />{" "}
            </ImageBox>
          </BoxRightMobile>
          <BoxLeft>
            <ContentWrapper>
              <BoxLeftMainHeader>
                Workflow approach to track candidate engagement
              </BoxLeftMainHeader>
              <BoxLeftContent1> </BoxLeftContent1>

              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Track your candidate engagement using workflows, create
                multiple workflows with multiple stages.
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Assign duration for each stage to initiate internal and
                external communication
                <br />
              </BoxLeftContent>
              {/* <BoxLeftContent>
                {" "}
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Track real-time progress of opportunities and manage your
                people, sales funnel at a granular level for better customer
                experience.
              </BoxLeftContent> */}
            </ContentWrapper>
          </BoxLeft>
          <BoxRight>
            <ImageBox>
              <img src={STAFFINGTHREETHIRD} alt="thirdimage" width="100%" />{" "}
            </ImageBox>
          </BoxRight>
        </Box>
        <Spacer />
        <Box>
          <BoxRightMobile>
            <ImageBox>
              <img src={STAFFINGTHREEFOURTH} alt="fourthimage" width="100%" />
            </ImageBox>
          </BoxRightMobile>
          <BoxRight>
            <ImageBox>
              <img src={STAFFINGTHREEFOURTH} alt="fourthimage" width="100%" />
            </ImageBox>
          </BoxRight>
          <BoxLeft>
            <ContentWrapper>
              <BoxLeftMainHeader>
                Cast your net wide yet accurately
              </BoxLeftMainHeader>
              <BoxLeftContent1> </BoxLeftContent1>
              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Improved Search on multiple parameters ensure you do not
                position unsuitable candidates.
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Korero also allows you to better search on certain
                parameters for the perfect talent.
                <br />
              </BoxLeftContent>
            </ContentWrapper>
          </BoxLeft>
        </Box>
        {/* <Spacer /> */}
        {/* <Box>
          <BoxRightMobile>
            <ImageBox>
              <img
                src={STAFFINGTHREEFIFTH}
                alt="outofbox3firstimage"
                width="100%"
              />{" "}
            </ImageBox>
          </BoxRightMobile>
          <BoxLeft>
            <ContentWrapper>
              <BoxLeftMainHeader>
                Lead your business with insights
              </BoxLeftMainHeader>
              <BoxLeftContent1>
                {" "}
                Leading a team is not easy, but with Korero no more waiting for
                reports and excel sheets, keep an eye on your entire company's
                sales performance in real-time, in one place, at your
                fingertips. Monitor all your efforts and strategies with
                comprehensive dashboards and lead the way for your sales teams
                to generate more revenue.
              </BoxLeftContent1>

              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Access company’s sales KPIs in real time, all in one
                place.
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Richly visualized dashboard, across your sales processes,
                even past performance comparisions!
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                {" "}
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Published in reporting currency for Leaders to make
                informed decisions and guide sales teams effectively.
              </BoxLeftContent>
            </ContentWrapper>
          </BoxLeft>
          <BoxRight>
            <ImageBox>
              <img
                src={STAFFINGTHREEFIFTH}
                alt="outofbox3firstimage"
                width="100%"
              />{" "}
            </ImageBox>
          </BoxRight>
        </Box> */}
        <Spacer />
      </MainBox>
    )
  }
}
export default BoxThree

const ContentWrapper = styled.div`
  /* border: 3px solid yellow; */
`

const MainBox = styled.div`
  padding: 40px;
  /* border: 1px solid red; */
  /* background: #eafaff; */
  @media screen and (max-width: 600px) {
    padding: 10px;
  }
`

const Box = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 0px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    flex-direction: column;
    padding: 0px;
  }

  /* margin-top: 20px; */
`
const BoxLeft = styled.div`
  width: 45%;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    width: 100%;
  }
`

const BoxLeftMainHeader = styled.h2`
  font-family: "Roboto";
  font-size: 2.2em;
  font-weight: 500;
  text-align: left;
  margin: 0 auto;
  letter-spacing: -0.5px;
  color: #080612;
`
const BoxLeftContent1 = styled.p`
  font-family: "Roboto";
  font-size: 1.1em;
  text-align: left;
  color: #6b6a71;
  padding: 10px 0px;
  font-weight: 100;
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`
const BoxLeftContent = styled.p`
  font-family: "Roboto";
  font-weight: 100;
  padding: 0px 10px;
  font-size: 1.1em;
  text-align: left;
  color: #6b6a71;
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`

const BoxRight = styled.div`
  width: 52%;
  /* padding: 0px 30px;
  margin-right: 70px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
  @media screen and (max-width: 600px) {
    width: 100%;
    display: none;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    display: none;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const ImageBox = styled.div`
  border: 11px solid rgb(221, 221, 221);
  border-radius: 7px;
  /* height: 480px; */
  background-color: whitesmoke;
  overflow-x: hidden;
  @media screen and (max-width: 600px) {
    /* height: 220px; */
    margin: 20px 10px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* height: 320px; */
    margin: 20px 10px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* height: 420px; */
    margin: 20px 10px;
  }
`
const Spacer = styled.div`
  /* background-color: #eafaff; */
  height: 110px;
`
const BoxRightMobile = styled.div`
  @media only screen and (min-width: 1200px) {
    display: none;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    display: block;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    display: none;
  }
`
