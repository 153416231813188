// import React from "react"

// import { LandingScreen } from "../Home/Styled"
// import { Link } from "gatsby"

// import { trackCustomEvent } from "gatsby-plugin-google-analytics"
// import { loginUrl } from "../../config/keys"

// function Homepage(){
//   return(
//   <div>
//     <div>
//     Korero helps organize your contact list and better manage candidate database so that staffing companies can leverage Korero for managing profiles as well as customer relationships. Korero is your go to platform for not only closing opportunities but also for tracking status of each profile positioned for the vacancy.

// RecruitPro is an in-app add on module that users from Staffing industry need to enable to access the additional features, admin of the enterprise can easily enable this module in the Control Tower found in the Users section. For more information kindly visit Korero Knowledge Hub. This module will be charged additionally.

// Users will be able to update each candidate’s profile with the key parameters like availability, billing rate and key words that helps narrow down the search for the potential profile.

// Create Workflow for each type of requirement, define the number of stages as deemed appropriate. Easy to use and simplified User Interface ensures that Workflows can be configured in moments and your team can start using the workflows and tagging candidates at the earliest. This also ensures governance with easy reporting of metrics. RecruitPro also enables automation of communication to the candidate on the result through email, further the message can be customized using templates.
//     </div>
//   </div>
//   )
// }
// export default Homepage

import React from "react"
import BoxOne from "./StaffingNewPage/BoxOne"

import BoxTwo from "./StaffingNewPage/BoxTwo"
import BoxFour from "./StaffingNewPage/BoxFour"
import BoxThree from "./StaffingNewPage/BoxThree"
import styled from "styled-components"
function Outoftheboxanalytics() {
  return (
    <div>
      <BoxOne />
      <BoxTwo />
      <Spacer />
      <BoxThree />
      <BoxFour />
    </div>
  )
}
export default Outoftheboxanalytics
const Spacer = styled.div`
  /* background-color: #eafaff; */
  height: 30px;
  @media screen and (max-width: 600px) {
    height: 30px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: 30px;
  }
`
