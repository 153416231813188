import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Staffing from "../modules/Solutions/Staffing"
// import { Helmet } from "react-helmet"

const SecondPage = () => (
  <Layout>
    <SEO title="Staffing" 
    description="Korero is your GoTo platform for not only closing opportunities but also for tracking status of each profile positioned for the vacancy.
    RecruitPro is an in-app add on module that users from Staffing industry need to enable to access the additional features,
    admin of the enterprise can easily enable this module in the Control Tower found in the Users section."/>
    <Staffing/>
    {/* <Helmet> */}
    {/* <script src="https://platform.linkedin.com/in.js" type="text/javascript">
      {" "}
      {/* lang: en_US */}
    {/* </script>  */}
    {/* </Helmet> */}
  </Layout>
)

export default SecondPage
