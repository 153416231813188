import React, { Component } from "react"
// import OUTOFBOX from "../../../images/outofbox1.png"
import STAFFING from "../../../images/staffing/homepage.png"
import styled from "styled-components"
import { loginUrl } from "../../../config/keys"

import { trackCustomEvent } from "gatsby-plugin-google-analytics"

class BoxOne extends Component {
  render() {
    return (
      <MainBox>
        <Box>
          <BoxLeft>
            <BoxLeftMainHeader>Boost your Placement Rate</BoxLeftMainHeader>
            <br />
            <BoxLeftContent>
              {" "}
              Staffing companies can give their placement rates a big boost by
              utilizing staffing solution from Korero. Get your recruiters and
              sales team on board, improve co-ordination, understand more about
              your candidates and place more candidates than your peers.
            </BoxLeftContent>
            <BoxLeftButton>
              <TrialLink1
                onClick={(e) => {
                  // To stop the page reloading
                  // e.preventDefault()
                  // Lets track that custom click
                  trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: "start your free trial  button in navbar ",
                    // string - required - Type of interaction (e.g. 'play')
                    action: "Click",
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    label: "Start your free trial button in navbar",
                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                    value: 43,
                  })
                  //... Other logic here
                }}
                href={`${loginUrl}/register/Option`}
                target="_blank"
              >
                Get Started
              </TrialLink1>
            </BoxLeftButton>
          </BoxLeft>
          <BoxRight>
            <img src={STAFFING} alt="staffing" width="100%" />
          </BoxRight>
        </Box>
      </MainBox>
    )
  }
}
export default BoxOne

const MainBox = styled.div`
  margin-top: 82px;
  background: #f8f8f8;
`

const Box = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    flex-direction: column;
  }

  /* margin-top: 20px; */
`
const BoxLeft = styled.div`
  /* border: 1px solid black; */
  width: 47%;
  margin: 20px 100px;
  padding: 40px;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 0px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    width: 100%;
    margin: 0px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    padding: 20px;
    margin: 20px 50px;
  }
`
const BoxLeftButton = styled.div`
  padding: 6px 1px;
`
const TrialLink1 = styled.a`
  /* display: inline-block; */
  text-decoration: none !important;
  font-family: "Roboto";
  padding: 11px 20px;
  margin-right: 20px;
  background: #ee6352;
  border-radius: 4px;
  font-style: normal;
  font-weight: 100;
  font-size: 1.25em;
  line-height: 26px;
  color: #ffffff !important;
`
const BoxLeftMainHeader = styled.h2`
  font-family: "Roboto";
  font-weight: 500;
  font-size: 3em;
  text-align: left;
  margin: 0 auto;
  letter-spacing: -0.5px;
  color: #080612;
`
const BoxLeftContent = styled.p`
  font-family: "Roboto";
  font-size: 1.125em;
  font-weight: 100;
  text-align: left;
  padding: 10px 0px;
  color: #6b6a71;
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`
const BoxRight = styled.div`
  width: 42%;
  padding: 0px 30px;
  margin-bottom: 15px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    width: 100%;
    margin: 0px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
